/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { createStyles } from '@material-ui/core';
import { container } from '../../../material-kit-react';

const downloadStyle = createStyles({
  section: {
    padding: '70px 0',
  },
  container,
  textCenter: {
    textAlign: 'center',
  },
  sharingArea: {
    marginTop: '80px',
  },
  socials: {
    maxWidth: '24px',
    marginTop: '0',
    width: '100%',
    transform: 'none',
    left: '0',
    top: '0',
    height: '100%',
    fontSize: '20px',
    marginRight: '4px',
  },
});

export default downloadStyle;
