/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
  dangerColor,
  grayColor,
  infoColor,
  primaryColor,
  roseColor,
  successColor,
  warningColor,
} from '../../material-kit-react';

const customLinearProgressStyle = {
  root: {
    height: '4px',
    marginBottom: '20px',
    overflow: 'hidden',
  },
  bar: {
    height: '4px',
  },
  primary: {
    backgroundColor: primaryColor,
  },
  warning: {
    backgroundColor: warningColor,
  },
  danger: {
    backgroundColor: dangerColor,
  },
  success: {
    backgroundColor: successColor,
  },
  info: {
    backgroundColor: infoColor,
  },
  rose: {
    backgroundColor: roseColor,
  },
  gray: {
    backgroundColor: grayColor,
  },
  primaryBackground: {
    background: 'rgba(156, 39, 176, 0.2)',
  },
  warningBackground: {
    background: 'rgba(255, 152, 0, 0.2)',
  },
  dangerBackground: {
    background: 'rgba(244, 67, 54, 0.2)',
  },
  successBackground: {
    background: 'rgba(76, 175, 80, 0.2)',
  },
  infoBackground: {
    background: 'rgba(0, 188, 212, 0.2)',
  },
  roseBackground: {
    background: 'rgba(233, 30, 99, 0.2)',
  },
  grayBackground: {
    background: 'rgba(221, 221, 221, 0.2)',
  },
};

export default customLinearProgressStyle;
