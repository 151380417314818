/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library to set properties for components
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { WithStyles } from '@material-ui/styles';
// nodejs library that concatenates classes
import classNames from 'classnames';
import React from 'react';
// @material-ui/icons
// core components
import cardStyle from '../../assets/jss/material-kit-react/components/cardStyle';

interface ICardProps extends WithStyles<typeof cardStyle> {
  className?: string;
  plain?: boolean;
  carousel?: boolean;
  children?: any;
}
function Card({ ...props }: ICardProps) {
  const { classes, className, children, plain, carousel, ...rest } = props;
  const cardClasses = classNames({
    [classes.card]: true,
    [classes.cardPlain]: plain,
    [classes.cardCarousel]: carousel,
    [className as string]: className !== undefined,
  });
  return (
    <div className={cardClasses} {...rest}>
      {children}
    </div>
  );
}

export default withStyles(cardStyle)(Card);
